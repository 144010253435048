import i18n from "../../../localizations/i18n";
import Autocomplete from "@material-ui/lab/Autocomplete";

import {
  TextField,
  useTheme,
  makeStyles,
  Chip,
  Theme,
} from "@material-ui/core";
import { hgemColours } from "../../../themes/defaultTheme";

interface IProps {
  availableQuestions: string[];
  selectedQuestions: string[];
  onApply: (questions: string[]) => void;
}

const useStyles = makeStyles<Theme>(() => ({
  container: {
    marginTop: "3%",
  },
  status: {
    color: hgemColours.DarkGrey,
    marginTop: "1%",
  },
  questionSelect: {
    "& label": {
      color: hgemColours.DarkGrey,
    },
  },
  clearIndicator: {
    visibility: "visible",
  },
}));

const AppealQuestionSelect = (props: IProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const onSelectionChange = (_e: unknown, value: string[]) => {
    props.onApply(value);
  };

  const showClearButton =
    props.selectedQuestions.length > 0 ? classes.clearIndicator : "";

  return (
    <>
      <div className={classes.container}>
        <Autocomplete
          id="appeals-questions-filter-options"
          className={classes.questionSelect}
          classes={{
            clearIndicator: showClearButton,
          }}
          multiple
          filterSelectedOptions
          value={props.selectedQuestions}
          options={props.availableQuestions}
          getOptionLabel={(option: string) => option}
          renderInput={(params) => (
            <TextField
              {...params}
              label={i18n.translate("QUESTIONS_PICKER_Selected_Questions")}
              variant="standard"
              placeholder={i18n.translate("QUESTIONS_PICKER_Search")}
            />
          )}
          getOptionSelected={(option: string) =>
            props.selectedQuestions.indexOf(option) > -1
          }
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="default"
                label={option}
                title={option}
                {...getTagProps({ index })}
              />
            ))
          }
          onChange={onSelectionChange}
        />
      </div>
      <div className={classes.actions}>
        <div className={classes.status}>{`${
          props.selectedQuestions.length
        } ${i18n.translate("QUESTIONS_PICKER_Selected")}`}</div>
      </div>
    </>
  );
};

export default AppealQuestionSelect;
