import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  LinearProgress,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import { AuthServiceInstance } from "../../authentication/authService";
import i18n from "../../localizations/i18n";
import {
  eLearningModule,
  getAccessToken,
  getModules,
} from "./api/eLearningApi";
import GemWheel from "./components/gemWheel";
import ModuleViewer from "./components/moduleViewer";
import { ELearningViewStyles } from "./styles/eLearningViewStyles";

const defaultModule = "gem-wheel-elearning-home";
enum ViewMode {
  moduleSelection = 0,
  moduleViewer = 1,
}

const ELearningView = () => {
  const classes = ELearningViewStyles();
  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.xs)
  );
  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.moduleSelection);
  const [accessToken, setAccessToken] = useState<string>("");
  const [modules, setModules] = useState<eLearningModule[]>([]);
  const [selectedModule, setSelectedModule] = useState<string>("");
  const [eLearningError, setELearningError] = useState<boolean>(false);

  useEffect(() => {
    const initialise = async () => {
      if (!accessToken) {
        const clientId = await AuthServiceInstance.getClientId();
        const userId = await AuthServiceInstance.getUserId();
        await getAccessToken(clientId, userId)
          .then((tokenResponse) => {
            if (tokenResponse.status === 200 && tokenResponse.data) {
              setAccessToken(tokenResponse.data);
            }
          })
          .catch(() => setELearningError(true));
      }
    };
    initialise();
  }, [accessToken]);

  useEffect(() => {
    const fetchModules = async () => {
      if (accessToken && modules.length === 0) {
        await getModules(accessToken)
          .then((moduleResponse) => {
            if (moduleResponse.status === 200 && moduleResponse.data) {
              setModules(moduleResponse.data);
            }
          })
          .catch(() => setELearningError(true));
      }
    };

    fetchModules();
  }, [accessToken, modules.length]);

  const getSelectedModule = (): eLearningModule | undefined => {
    let module = modules.find(
      (x) => x.Id.toLowerCase() === `${selectedModule.toLowerCase()}-module`
    );

    if (!module) {
      module = modules.find((x) => x.Id === `${defaultModule}-module`);
    }

    return module;
  };

  const getModulePath = (): string => {
    const module = getSelectedModule();
    if (module && module.Content && module.Content.length > 0) {
      return module.Content[0].ModulePath;
    }

    return "";
  };

  const SectionDescription = () => {
    const module = getSelectedModule();
    if (module && module.Section) {
      return (
        <div
          className={classes.sectionDescription}
          dangerouslySetInnerHTML={{ __html: i18n.translate(module.Section) }}
        ></div>
      );
    }

    return null;
  };

  const ModuleDescription = () => {
    const module = getSelectedModule();
    if (module && module.Description) {
      return (
        <div className={classes.moduleDescription}>
          <h4>{i18n.translate(module.Title)}</h4>
          <div
            dangerouslySetInnerHTML={{
              __html: i18n.translate(module.Description),
            }}
          ></div>
        </div>
      );
    }

    return null;
  };

  const ModuleLauncher = () => {
    const module = getSelectedModule();
    if (module && module.Content && module.Content.length > 0) {
      return (
        <Button
          variant="contained"
          color="primary"
          className={classes.launchButton}
          startIcon={<PlayCircleOutlineIcon />}
          onClick={() => setViewMode(ViewMode.moduleViewer)}
        >
          {i18n.translate("ELEARNING_VIEWER_Launch_Module")}
        </Button>
      );
    }

    return null;
  };

  const MobileContent = () => {
    return (
      <>
        <ModuleLauncher />
        <div style={{ marginTop: "5%" }}>
          <SectionDescription />
        </div>
        <ModuleDescription />
      </>
    );
  };

  return (
    <div className={classes.container}>
      {!eLearningError && viewMode === ViewMode.moduleSelection && (
        <Grid container className={classes.gridContainer}>
          <Grid item xs={12} sm={12} md={6} lg={5}>
            <GemWheel
              selectedId={selectedModule ? selectedModule : defaultModule}
              onSelected={(id) => setSelectedModule(id)}
            />
          </Grid>

          {!accessToken && modules.length === 0 && (
            <Grid item xs={12} sm={12} md={6} lg={7}>
              <LinearProgress />
            </Grid>
          )}

          <Grid item xs={12} sm={12} md={6} lg={7}>
            {!isMobileView && (
              <>
                <SectionDescription />
                <ModuleLauncher />
                <ModuleDescription />
              </>
            )}
            {isMobileView && <MobileContent />}
          </Grid>
        </Grid>
      )}

      {!eLearningError &&
        viewMode === ViewMode.moduleViewer &&
        selectedModule && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button onClick={() => setViewMode(ViewMode.moduleSelection)}>
                {i18n.translate("ELEARNING_VIEWER_Back_to_Selection")}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <ModuleViewer
                accessToken={accessToken}
                modulePath={getModulePath()}
              />
            </Grid>
          </Grid>
        )}

      {eLearningError && (
        <p>{i18n.translate("ELEARNING_VIEWER_Error_Message")}</p>
      )}
    </div>
  );
};

export default ELearningView;
