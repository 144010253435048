import { createSelector } from "reselect";
import { ITaskCentreCaseListState } from "../reducers/TaskCentre-CaseList-Reducer";
import { RootState } from "../reducers/RootReducer";

export const TaskCentreCasesQuery = createSelector(
  (state: RootState) => state.TaskCentreCaseListReducer,
  (state: ITaskCentreCaseListState) => {
    return state.query;
  }
);

export const TaskCentreCases = createSelector(
  (state: RootState) => state.TaskCentreCaseListReducer,
  (state: ITaskCentreCaseListState) => {
    return state.taskCentreCases;
  }
);

export const TaskCentreCasesCount = createSelector(
  (state: RootState) => state.TaskCentreCaseListReducer,
  (state: ITaskCentreCaseListState) => {
    return state.totalCount;
  }
);

export const SelectedCaseId = createSelector(
  (state: RootState) => state.TaskCentreCaseListReducer,
  (state: ITaskCentreCaseListState) => {
    return state.taskCentreCases.selectedCaseId;
  }
);

export const FilteredCaseId = createSelector(
  (state: RootState) => state.TaskCentreCaseListReducer,
  (state: ITaskCentreCaseListState) => {
    return state.taskCentreCases.filteredCaseId;
  }
);
