import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../../localizations/i18n";
import {
  downloadVisitReportPdf,
  fetchVisitReportHtml,
} from "../../../state/actions/VisitReport-Actions";
import { VisitReportContent } from "../state/selectors";
import { PandoraReportStyles } from "../styles/pandoraReportStyles";
import IsAuthorised from "../../../authorisation/permissions";
import { PermissionType } from "../../../authorisation/permissionsUtils";
import MoreActionsMenu from "../../_common/moreActionsMenu/moreActionsMenu";
import QuarantineConfirmationDialog from "../../_common/moreActionsMenu/quarantineConfirmationDialog";
import { postQuarantineVisitApi } from "../../../state/api/VisitDetails-API";
import { AuthServiceInstance } from "../../../authentication/authService";
import QuarantineErrorDialog from "../../_common/moreActionsMenu/quarantineErrorDialog";

interface IProps {
  visitId: number;
  minContainerWidth: number;
  onQuarantine?: () => void;
  isDeletableVisitType: boolean;
}

const PandoraReport = (props: IProps) => {
  const classes = PandoraReportStyles();
  const dispatch = useDispatch();
  const containerRef = useRef<HTMLDivElement>(null);
  const reportContent = useSelector(VisitReportContent);
  const [containerWidth, setContainerWidth] = useState(0);
  const [userCanQuarantine, setUserCanQuarantine] = useState(false);
  const [quarantineDialogOpen, setQuarantineDialogOpen] = useState(false);
  const [quarantineErrorDialogOpen, setQuarantineErrorDialogOpen] =
    useState(false);

  useEffect(() => {
    const checkUserCanQuarantine = async () => {
      const canQuarantine = await IsAuthorised(PermissionType.QuarantineVisits);

      if (canQuarantine && props.isDeletableVisitType) {
        setUserCanQuarantine(canQuarantine);
      }
    };
    checkUserCanQuarantine();
  });

  const downloadVisitReport = () => {
    dispatch(downloadVisitReportPdf(props.visitId));
  };

  useEffect(() => {
    if (
      containerWidth > 0 &&
      props.visitId &&
      props.visitId !== reportContent?.visitId
    ) {
      dispatch(fetchVisitReportHtml(props.visitId));
    }
  }, [containerWidth, dispatch, props.visitId, reportContent]);

  useLayoutEffect(() => {
    if (containerRef.current) {
      const width =
        containerRef.current.clientWidth > props.minContainerWidth
          ? containerRef.current.clientWidth
          : props.minContainerWidth;

      setContainerWidth(width - 10);
    }
  }, [props.minContainerWidth]);

  const quarantineVisit = async () => {
    const clientId = await AuthServiceInstance.getClientId();
    try {
      await postQuarantineVisitApi(clientId, props.visitId);
      props.onQuarantine?.();
    } catch (error) {
      console.log("Error:", error);
      setQuarantineErrorDialogOpen(true);
    }
  };

  const moreActionsMenuItems = [
    {
      title: i18n.translate("VISIT_DETAILS_REPORT_PDF_Download"),
      icon: "fa-file-arrow-down",
      onClick: downloadVisitReport,
    },
    ...(userCanQuarantine
      ? [
          {
            title: i18n.translate("MORE_ACTIONS_MENU_Quarantine"),
            icon: "fa-trash",
            onClick: () => setQuarantineDialogOpen(true),
          },
        ]
      : []),
  ];

  return (
    <div ref={containerRef} className={classes.container}>
      {reportContent?.htmlContent && reportContent.htmlContent.length > 0 && (
        <>
          <MoreActionsMenu menuItems={moreActionsMenuItems} />
          <div
            className={classes.frame}
            dangerouslySetInnerHTML={{ __html: reportContent.htmlContent }}
          ></div>
          {!!userCanQuarantine && (
            <>
              <QuarantineConfirmationDialog
                isOpen={quarantineDialogOpen}
                setIsOpen={setQuarantineDialogOpen}
                quarantineVisit={quarantineVisit}
              />
              <QuarantineErrorDialog
                isOpen={quarantineErrorDialogOpen}
                setIsOpen={setQuarantineErrorDialogOpen}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PandoraReport;
