import { makeStyles, Theme, useTheme } from "@material-ui/core";
import { hgemColours } from "../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  gridContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    listStyle: "none",
    position: "relative",
  },
  imageList: {
    width: "100%",
  },
  smallPadding: {
    padding: "2%",
  },
  flexImage: {
    margin: "0.5%",
    height: "200px",
    width: "200px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "260px",
      marginBottom: "3%",
    },
    "&:hover .MuiCheckbox-root": {
      transform: "translate(0,0)",
    },
  },
  mediaContainer: {
    height: "100%",
    width: "10%",
    position: "relative",
  },
  imageContainer: {
    width: "100%",
  },
  mediaOverlay: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    fontSize: "0.85rem",
    lineHeight: 2,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  clippedText: {
    maxWidth: "150px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  textMessage: {
    fontStyle: "italic",
    color: `${hgemColours.DarkGrey}`,
    fontSize: "1em",
    marginLeft: "38%",
    [theme.breakpoints.down("md")]: {
      marginLeft: "21%",
    },
  },
  mediaLoadingBar: {
    width: "98%",
    marginLeft: "0.6%",
  },
  checkbox: {
    position: "absolute",
    top: ".5rem",
    right: ".5rem",
    background: "pink",
    padding: "0",
    zIndex: 1,
    transform: "translate(200px, 0)",
    transition: "transform .3s",
    "& .MuiSvgIcon-root": {
      background: "white",
      borderRadius: "5px",
    },
    "&.Mui-checked": {
      transform: "translate(0,0)",
    },
  },
  actionsContainer: {
    maxHeight: 0,
    width: "100%",
    overflow: "hidden",
    transition: "max-height 0.3s ease-out",
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "1rem",
    "&.expanded": {
      maxHeight: "2.5rem",
    },
  },
  downloadIcon: {
    marginRight: ".75rem",
  },
}));

export const MediaStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
