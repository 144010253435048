import { WorkflowStatus } from "./TaskCentreCases";

export interface IGetCaseFilters {
  visitId?: number;
  userId?: number;
  workflowStatuses: WorkflowStatus[];
  categories: number[];
  priorities: number[];
  createdBetween: IDateRange | null;
  visitPeriods: string[];
  periodHierarchies?: number[];
  locationIds: number[];
  brands: string[];
  visitTypes: string[];
  visitSources: number[];
  feedbackCategories: string[];
  feedbackSubCategories: string[];
  daysOfWeek: string[];
  questionnaireTypes: string[];
  assignees: number[];
  contactStatus: string;
  visitScoreRange?: IVisitScoreRange;
  npsScores: number[];
  // dishes: number[];
  caseId?: number | null;
}

export interface IGetCasesQuery extends IGetCaseFilters {
  sort: ISort;
  pagination: IPagination;
}

export interface IDateRange {
  start?: Date;
  end: Date;
}

export interface IVisitScoreRange {
  min: number;
  max: number;
}

export interface ISort {
  property: SortBy;
  order: SortOrder;
}

export interface IPagination {
  itemsPerPage: number;
  pageIndex: number;
}

export enum SortBy {
  CreatedDate = "createdDate",
}

export enum SortOrder {
  Ascending,
  Descending,
}
