import i18n from "../localizations/i18n";
import {
  ContactStatusType,
  HubFilterType,
  ICaseIdFilter,
  IHubFilter,
  IHubKeyValueFilter,
  IHubNumericRangeFilter,
  IHubTextFilter,
  ILocationFilter,
  ILocationRankFilter,
  IVisitContactStatusFilter,
  IVisitDateFilter,
  IVisitIdFilter,
  VisitDateSelectionType,
} from "../state/types/FilterSets";
import {
  visitDateSelectionTypeIsRelativeDate,
  generateRelativeDateFromSelectionType,
} from "../utils/visitDateFilterUtils";

export interface IFilterDates {
  startDate: Date | undefined;
  endDate: Date | undefined;
  periods: string[] | undefined;
  periodHierarchies: number[] | undefined;
}

export interface IFilterScoreRange {
  min: number;
  max: number;
}

export function getFilterSelection(
  filterType: HubFilterType,
  filters: IHubFilter[],
  defaultReturnValue:
    | Array<string>
    | Array<number>
    | string
    | number
    | IFilterDates
    | IFilterScoreRange
    | null = []
):
  | Array<string>
  | Array<number>
  | string
  | number
  | IFilterDates
  | IFilterScoreRange
  | null
  | undefined {
  const selectedFilter = filters.find((x) => x.type === filterType);
  if (selectedFilter !== undefined) {
    switch (selectedFilter.type) {
      case HubFilterType.VisitType:
      case HubFilterType.AreasAndRegions:
      case HubFilterType.QuestionnaireType:
      case HubFilterType.Sections:
      case HubFilterType.Questions:
      case HubFilterType.QuestionCategory:
      case HubFilterType.Benchmarks:
      case HubFilterType.NPS:
      case HubFilterType.DayOfWeek:
      case HubFilterType.DayPart:
      case HubFilterType.VisitAnalysisDepartment:
      case HubFilterType.VisitAnalysisRoom:
      case HubFilterType.FeedbackCategory:
      case HubFilterType.FeedbackSubCategory:
        {
          const textFilter = selectedFilter as IHubTextFilter;
          if (textFilter.value.length > 0) {
            return textFilter.value.map((x) => x);
          }
        }
        break;

      case HubFilterType.VisitId: {
        const visitIdFilter = selectedFilter as IVisitIdFilter;
        return visitIdFilter.value ?? undefined;
      }

      case HubFilterType.CaseId: {
        const caseIdFilter = selectedFilter as ICaseIdFilter;
        return caseIdFilter.value ?? undefined;
      }

      case HubFilterType.VisitDate:
        {
          const dateFilter = selectedFilter as IVisitDateFilter;
          if (dateFilter) {
            return getFilteredDates(dateFilter);
          }
        }
        break;
      case HubFilterType.Segments:
      case HubFilterType.SegmentFilter:
      case HubFilterType.Locations:
        {
          const locationFilter = selectedFilter as ILocationFilter;
          if (locationFilter) {
            return locationFilter.value.map((x) => x.key);
          }
        }
        break;

      case HubFilterType.LocationRank:
        {
          const locationRankFIlter = selectedFilter as ILocationRankFilter;
          if (locationRankFIlter) {
            return locationRankFIlter.selectedLocation?.key ?? 0;
          }
        }
        break;

      case HubFilterType.VisitContactStatus: {
        const contactStatusFilter = selectedFilter as IVisitContactStatusFilter;

        if (contactStatusFilter.value.length === 1) {
          return contactStatusFilter.value[0];
        } else {
          return ContactStatusType.ShowAll;
        }
      }

      case HubFilterType.QuestionScoreRange:
      case HubFilterType.VisitScoreRange:
        {
          const rangeFilter = selectedFilter as IHubNumericRangeFilter;
          if (rangeFilter) {
            return {
              min: rangeFilter.minValue,
              max: rangeFilter.maxValue,
            } as IFilterScoreRange;
          }
        }
        break;

      case HubFilterType.TaskCentreStatus:
      case HubFilterType.AppealStatus:
      case HubFilterType.TaskCentreCategory:
      case HubFilterType.TaskCentrePriority:
      case HubFilterType.VisitSource:
      case HubFilterType.TaskAssignee:
      case HubFilterType.DishFilters:
        {
          const keyValueFilter = selectedFilter as IHubKeyValueFilter;
          if (keyValueFilter) {
            return keyValueFilter.value.map((x) => x.key);
          }
        }
        break;
    }
  }

  return defaultReturnValue;
}

const getFilteredDates = (dateFilter: IVisitDateFilter): IFilterDates => {
  let startDate: Date | undefined = undefined;
  let endDate: Date = new Date();
  let periods: string[] | undefined = undefined;
  let periodHierarchies: number[] | undefined = undefined;

  if (dateFilter) {
    const isRelativeDate = visitDateSelectionTypeIsRelativeDate(
      dateFilter.dateSelectionType
    );

    if (isRelativeDate) {
      const dates = generateRelativeDateFromSelectionType(
        dateFilter.dateSelectionType
      );
      startDate = dates.dateStart;
      endDate = dates.dateEnd;
    } else if (
      dateFilter.dateSelectionType === VisitDateSelectionType.Custom &&
      dateFilter.customDateRange
    ) {
      startDate = dateFilter.customDateRange?.dateStart;
      endDate = dateFilter.customDateRange?.dateEnd;
    } else if (
      dateFilter.dateSelectionType === VisitDateSelectionType.reportingPeriods
    ) {
      periodHierarchies = dateFilter.selectedPeriodHierarchies;
    } else if (
      dateFilter.selectedPeriods &&
      dateFilter.selectedPeriods.length > 0
    ) {
      periods = dateFilter.selectedPeriods;
    }
  }

  return {
    startDate,
    endDate,
    periods,
    periodHierarchies,
  };
};

export const convertNpsClassificationToScores = (
  npsClassifications: string[]
): number[] => {
  const scores: number[] = [];

  npsClassifications.forEach((nps) => {
    switch (nps) {
      case i18n.translate("QUESTION_NPS_PICKER_VALUES_Promoter"):
        scores.push(9, 10);
        break;
      case i18n.translate("QUESTION_NPS_PICKER_VALUES_Neutral"):
        scores.push(7, 8);
        break;
      case i18n.translate("QUESTION_NPS_PICKER_VALUES_Detractor"):
        scores.push(1, 2, 3, 4, 5, 6);
        break;
    }
  });

  return scores;
};
