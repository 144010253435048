import { Button, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { useEffect } from "react";
import IsAuthorised from "../../../authorisation/permissions";
import { PermissionType } from "../../../authorisation/permissionsUtils";
import i18n from "../../../localizations/i18n";
import ChatIcon from "@material-ui/icons/Chat";
import ConfirmationDialog from "../../_common/confirmationDialog/confirmationDialog";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../state/reducers/RootReducer";
import {
  ApplyTextSubstitutions,
  textSubstituionKey,
  textSubstitution,
} from "../../../utils/textManipulations";
import {
  GetActiveNotificationById,
  GetResponseTemplatesForReview,
} from "../state/selectors";
import { NotificationType } from "../../../state/types/AppNotification";
import { deleteAppNotification } from "../../../state/actions/AppNotification-Actions";
import { ReviewResponseStyles } from "../styles/reviewResponseStyles";
import { postReviewResponse } from "../../../state/actions/VisitDetails-Actions";
import ReviewTemplateDialog from "./reviewTemplateDialog";
import {
  IReviewContent,
  IReviewResponseTemplate,
  ReviewResponseTemplateType,
} from "../../../state/types/VisitReport";
import dayjs from "dayjs";
import AIResponseContainer from "./aIResponseContainer";
import { AIResponses, fetchAIResponsesApi } from "../state/api";
import { Skeleton } from "@material-ui/lab";

interface IProps {
  visitId: number;
  review: IReviewContent;
}

const allowResponsesAfter =
  process.env.REACT_APP_REVIEW_RESPONSE_ENABLED_AFTER_DATE ??
  "2022-01-14T00:00:00";
const enabledSourcesConfig =
  process.env.REACT_APP_REVIEW_RESPONSE_ENABLED_SOURCES ?? "";

const enabledSources = enabledSourcesConfig.split(",");
const ReviewResponse = (props: IProps) => {
  const classes = ReviewResponseStyles();
  const dispatch = useDispatch();

  const fieldNotification = useSelector((state: RootState) =>
    GetActiveNotificationById(state, `${props.visitId}|posted-review-response`)
  );

  const responseTemplates = useSelector((state: RootState) =>
    GetResponseTemplatesForReview(state, props.review)
  );
  const fullTemplates = responseTemplates.filter(
    (x) => x.templateType === ReviewResponseTemplateType.Full
  );
  const introTemplates = responseTemplates.filter(
    (x) => x.templateType === ReviewResponseTemplateType.Introduction
  );
  const signatureTemplates = responseTemplates.filter(
    (x) => x.templateType === ReviewResponseTemplateType.Signature
  );

  const substitutions: textSubstitution[] = [
    {
      key: textSubstituionKey.ReviewAuthor,
      value: props.review.author,
    },
    {
      key: textSubstituionKey.ReviewScore,
      value: props.review.score?.toString(),
    },
    {
      key: textSubstituionKey.BranchName,
      value: props.review.location,
    },
    {
      key: textSubstituionKey.ClientName,
      value: props.review.client,
    },
    {
      key: textSubstituionKey.BrandName,
      value: props.review.brand,
    },
    {
      key: textSubstituionKey.BrandOrClientName,
      value: props.review.brand ?? props.review.client,
    },
  ];

  const hasError = fieldNotification
    ? fieldNotification.Type === NotificationType.Error
    : false;

  const isBusy = fieldNotification
    ? fieldNotification.Type === NotificationType.Busy
    : false;

  const [userCanRespond, setUserCanRespond] = useState(true);
  const [reviewIsRespondable, setReviewIsRespondable] = useState(false);
  const [responseText, setResponseText] = useState("");
  const [fetchingResponses, setFetchingResponses] = useState(false);

  const [templateSelections, setTemplateSelections] = useState<
    IReviewResponseTemplate[]
  >([]);
  const [clientUsesTemplates, setClientUsesTemplates] =
    useState<boolean>(false);
  const [aIResponses, setAIResponses] = useState<AIResponses | null>(null);
  const [responseInjected, setResponseInjected] = useState(false);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    setResponseText(text);
  };

  const resetText = () => {
    setResponseText("");
  };

  const charsRemaining = () => {
    const remaining = 2000 - responseText.length;
    const statusClass = remaining < 0 ? "char-overflow" : "";

    return (
      <span className={`${classes.charsRemaining} ${statusClass}`}>
        {remaining}{" "}
        {i18n.translate("VISIT_DETAILS_REVIEW_RESPONSE_Remaining_Chars")}
      </span>
    );
  };

  const submitComment = () => {
    if (
      responseText &&
      responseText.trim().length > 0 &&
      props.visitId &&
      props.review.reviewId
    ) {
      dispatch(
        postReviewResponse(props.visitId, props.review.reviewId, responseText)
      );
    }

    setResponseText("");
  };

  const aknowledgeError = () => {
    if (
      fieldNotification &&
      fieldNotification.Type === NotificationType.Error
    ) {
      dispatch(deleteAppNotification(fieldNotification));
    }
  };

  const selectResponseTemplate = (templates: IReviewResponseTemplate[]) => {
    if (templates.length > 0) {
      if (templates.length === 1) {
        selectResponseTemplae(templates[0]);
      } else {
        setTemplateSelections(templates);
      }
    }
  };

  const selectResponseTemplae = (template: IReviewResponseTemplate) => {
    if (template) {
      const tokanisedText = ApplyTextSubstitutions(
        template.template,
        substitutions
      );

      switch (template.templateType) {
        default:
        case ReviewResponseTemplateType.Full:
          setResponseText(tokanisedText);
          break;
        case ReviewResponseTemplateType.Introduction:
          setResponseText(`${tokanisedText}\r\n${responseText}`);
          break;
        case ReviewResponseTemplateType.Signature:
          setResponseText(`${responseText}\r\n${tokanisedText} `);
          break;
      }
    }

    setTemplateSelections([]);
  };

  useEffect(() => {
    const checkClientUsesTemplates = async () => {
      const clientUsesTemplates = await IsAuthorised(
        PermissionType.ResponseTemplates
      );

      setClientUsesTemplates(clientUsesTemplates);
    };
    const checkUserCanRespond = async () => {
      const userCanRespond = await IsAuthorised(
        PermissionType.RespondToReviews
      );
      setUserCanRespond(userCanRespond);
    };

    const checkReviewIsRespondable = () => {
      const source = props.review.reviewSource;
      const reviewDate = props.review.date;
      let canPostAfterTheFourteenth = false;

      if (reviewDate) {
        const reviewDateJs = dayjs(reviewDate);
        const reviewResponseCutOff = dayjs(allowResponsesAfter);
        canPostAfterTheFourteenth = reviewDateJs.isAfter(reviewResponseCutOff);
      }
      if (
        canPostAfterTheFourteenth &&
        props.review.reviewSource &&
        enabledSources.find((x) => x.toLowerCase() === source?.toLowerCase())
      ) {
        setReviewIsRespondable(true);
      }
    };
    checkClientUsesTemplates();
    checkUserCanRespond();
    checkReviewIsRespondable();
  }, [props.review]);

  const fetchResponses = async () => {
    setFetchingResponses(true);
    /* eslint-disable-next-line no-control-regex */
    const regex = /[^\x00-\x7F]/g;
    const responses = await fetchAIResponsesApi(
      props.review.author ?? "guest",
      JSON.stringify(props.review.reviewText?.replace(regex, "")).slice(
        1,
        -1
      ) ?? ""
    );

    setAIResponses(responses ?? null);
    setFetchingResponses(false);
    return responses;
  };

  const canGenerateResponses =
    !fetchingResponses &&
    !aIResponses &&
    props.review.reviewText?.length &&
    props.review.reviewText?.split(" ").length > 3;

  return userCanRespond && reviewIsRespondable ? (
    <span>
      <div className={classes.inputContainer}>
        {clientUsesTemplates && (
          <div className={classes.templateTasks}>
            {fullTemplates.length > 0 && (
              <Button
                color="primary"
                size="small"
                className={classes.templateButton}
                variant="contained"
                onClick={() => selectResponseTemplate(fullTemplates)}
              >
                {i18n.translate("VISIT_DETAILS_REVIEW_RESPONSE_Use_Template")}
              </Button>
            )}

            {introTemplates.length > 0 && (
              <Button
                color="primary"
                size="small"
                className={classes.templateButton}
                variant="contained"
                onClick={() => selectResponseTemplate(introTemplates)}
              >
                {i18n.translate(
                  "VISIT_DETAILS_REVIEW_RESPONSE_Insert_Introduction"
                )}
              </Button>
            )}

            {signatureTemplates.length > 0 && (
              <Button
                color="primary"
                size="small"
                className={classes.templateButton}
                variant="contained"
                onClick={() => selectResponseTemplate(signatureTemplates)}
              >
                {i18n.translate(
                  "VISIT_DETAILS_REVIEW_RESPONSE_Insert_Signature"
                )}
              </Button>
            )}

            {(introTemplates.length > 0 || signatureTemplates.length > 0) && (
              <Button
                color="primary"
                size="small"
                className={classes.templateButton}
                variant="contained"
                onClick={resetText}
              >
                {i18n.translate("VISIT_DETAILS_REVIEW_RESPONSE_Reset")}
              </Button>
            )}

            {canGenerateResponses && (
              <Button
                color="primary"
                size="small"
                className={classes.aiButton}
                variant="contained"
                onClick={fetchResponses}
              >
                {i18n.translate("VISIT_DETAILS_REVIEW_RESPONSE_Generate")}
              </Button>
            )}
          </div>
        )}

        {fetchingResponses && (
          <div className={classes.AIResponsesRow}>
            <Skeleton animation="wave" />
          </div>
        )}

        {aIResponses && (
          <AIResponseContainer
            responses={aIResponses}
            onSelected={(v) => {
              setResponseText(v);
              setResponseInjected(true);
            }}
          />
        )}

        <TextField
          multiline
          size="small"
          className={classes.responseInput}
          label={i18n.translate(
            "VISIT_DETAILS_REVIEW_RESPONSE_Respond_to_Review"
          )}
          value={responseText}
          onChange={onChange}
          rows={responseInjected ? 5 : 3}
          variant="outlined"
          inputProps={{ spellCheck: "true" }}
          fullWidth
        />
        <div className={classes.actionContainer}>
          {charsRemaining()}

          {!hasError && (
            <Button
              color="primary"
              size="small"
              className={classes.postButton}
              variant="contained"
              disabled={
                responseText.length === 0 ||
                responseText.length > 2000 ||
                isBusy
              }
              onClick={submitComment}
              startIcon={<ChatIcon />}
            >
              {i18n.translate("VISIT_DETAILS_REVIEW_RESPONSE_Post_Response")}
            </Button>
          )}
        </div>
      </div>
      <ConfirmationDialog
        title={i18n.translate("VISIT_DETAILS_REVIEW_RESPONSE_Error_Title")}
        message={i18n.translate("VISIT_DETAILS_REVIEW_RESPONSE_Error_Message")}
        showConfirmation={hasError}
        onConfirmCallback={aknowledgeError}
        confirmButtonText={i18n.translate(
          "VISIT_DETAILS_REVIEW_RESPONSE_Close"
        )}
      />
      {responseTemplates && responseTemplates.length > 1 && (
        <ReviewTemplateDialog
          showSelections={templateSelections.length > 1}
          templates={templateSelections}
          substitutions={substitutions}
          onSelect={selectResponseTemplae}
          onClose={() => setTemplateSelections([])}
        />
      )}
    </span>
  ) : null;
};

export default ReviewResponse;
