import { makeStyles, Theme, useTheme } from "@material-ui/core";
import { hgemColours } from "../../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>(() => ({
  moreActionsContainer: {
    display: "inline-flex",
    justifyContent: "end",
    width: "100%",
    backgroundColor: hgemColours.OffWhite,
    marginBottom: "1%",
  },
  moreActionsMenu: {
    transformOrigin: "top right",
  },
  icon: {
    fontSize: "medium",
  },
  iconLeft: {
    marginRight: "15px",
  },
  iconRight: {
    marginLeft: "15px",
  },
}));

export const MoreActionsMenuStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
