import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "../state/reducers/RootReducer";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import { registerUserProfileSagas } from "../state/sagas/UserProfile-Saga";
import { registerUserImpersonationSagas } from "../state/sagas/UserImpersonation-Saga";
import { registerTaskCentreCaseListSagas } from "../state/sagas/TaskCentre-CaseList-Saga";
import { registerAppNotificationSagas } from "../state/sagas/AppNotification-Saga";
import { registerGuestDetailsSagas } from "../state/sagas/GuestDetails-Saga";
import { registerVisitReportSagas } from "../state/sagas/VisitReport-Saga";
import { registerVisitDetailsSagas } from "../state/sagas/VisitDetails-Saga";
import { registerApplicationSagas } from "../state/sagas/Application-Saga";
import { registerAppealsSagas } from "./sagas/Appeals-Saga";
import { registerConversationsSagas } from "./sagas/Conversations-Saga";
import { registerHubVersionUpdateSagas } from "./sagas/HubVersionUpdate-Saga";

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: [
    "ReportConfigurationReducer",
    "FilterOptionsReducer",
    "FilterSetsReducer",
    "TaskCentreCaseListReducer",
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk, sagaMiddleware))
);

registerHubVersionUpdateSagas(sagaMiddleware);
registerApplicationSagas(sagaMiddleware);
registerUserProfileSagas(sagaMiddleware);
registerUserImpersonationSagas(sagaMiddleware);
registerTaskCentreCaseListSagas(sagaMiddleware);
registerAppNotificationSagas(sagaMiddleware);
registerGuestDetailsSagas(sagaMiddleware);
registerVisitReportSagas(sagaMiddleware);
registerVisitDetailsSagas(sagaMiddleware);
registerAppealsSagas(sagaMiddleware);
registerConversationsSagas(sagaMiddleware);

export const persistor = persistStore(store);
