import React, { useState, useEffect } from "react";
import {
  Button,
  IconButton,
  makeStyles,
  TextField,
  Theme,
  useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import FilterWrapper from "./filterWrapper";
import { i18n } from "../../../localizations";
import { useDispatch } from "react-redux";
import { setFilteredTaskCentreCase } from "../../../state/actions/TaskCentre-CaseList-Actions";

interface IProps {
  selectedCaseId: number | undefined;
  applySelectionCallback: (visitId: number | undefined) => void;
  minInputLength?: number | undefined;
  label?: string | undefined;
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  innerContainer: {
    width: "250px",
    padding: "10px",
    display: "inline-block",
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  actions: {
    margin: "20px 10px 10px 10px",

    "& button": {
      float: "right",
      marginLeft: "10px",
      bottom: "8px",
    },
  },
  input: {
    width: "100%",
    float: "left",

    "& label": {
      color: theme.palette.grey[400],
    },
  },
}));

const CaseIdPicker = (props: IProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedCaseId, setSelectedCaseId] = useState("");
  const isValidSearch = !(
    isNaN(Number(selectedCaseId)) && selectedCaseId.length > 0
  );

  const dispatch = useDispatch();

  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const getAppliedSelectionText = (): string => {
    let selectionText = i18n.translate("CASE_ID_PICKER_No_Id_Set");
    if (selectedCaseId) {
      selectionText = selectedCaseId.toString();
    }

    return selectionText;
  };

  const buildTooltipContent = (): string => {
    let tooltipText = i18n.translate("CASE_ID_PICKER_No_Id_Set");
    if (props.selectedCaseId) {
      tooltipText = `${
        props.label ?? i18n.translate("CASE_ID_PICKER_CaseId")
      }: ${props.selectedCaseId.toString()}`;
    }

    return tooltipText;
  };

  const applySelection = () => {
    const caseId = Number(selectedCaseId);

    const newCaseId =
      selectedCaseId.length > 0 && !isNaN(caseId) ? caseId : undefined;

    props.applySelectionCallback(newCaseId);
    dispatch(setFilteredTaskCentreCase(newCaseId));

    setIsOpen(false);
  };

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trim();
    setSelectedCaseId(value);
  };

  const clearSearch = () => {
    setSelectedCaseId("");
  };

  const cancelSelection = () => {
    setSelectedCaseId(
      props.selectedCaseId ? props.selectedCaseId.toString() : ""
    );
    setIsOpen(false);
  };

  useEffect(() => {
    setSelectedCaseId(
      props.selectedCaseId ? props.selectedCaseId.toString() : ""
    );
  }, [props.selectedCaseId]);

  return (
    <FilterWrapper
      label={props.label ?? i18n.translate("CASE_ID_PICKER_CaseId")}
      selectionHint={getAppliedSelectionText()}
      isOpen={isOpen}
      toggleOpenState={handleToggleOpen}
      tooltip={buildTooltipContent()}
      highlight={Boolean(props.selectedCaseId)}
    >
      <div className={classes.outerContainer}>
        <div className={classes.innerContainer}>
          <TextField
            label={props.label ?? i18n.translate("CASE_ID_PICKER_CaseId")}
            value={selectedCaseId}
            onChange={handleValueChange}
            variant="outlined"
            size="small"
            className={classes.input}
            error={!isValidSearch && selectedCaseId.length > 0}
            inputProps={{ maxLength: 9 }}
            InputProps={{
              endAdornment: (
                <IconButton
                  disabled={selectedCaseId.length === 0}
                  size="small"
                  title={i18n.translate("CASE_ID_PICKER_ClearSearch")}
                  onClick={clearSearch}
                >
                  <CloseIcon />
                </IconButton>
              ),
            }}
          />
        </div>
        <div className={classes.actions}>
          <Button
            onClick={applySelection}
            variant="contained"
            color="primary"
            disabled={
              (!isValidSearch && selectedCaseId.length > 0) ||
              (selectedCaseId.length > 0 &&
                selectedCaseId.length < (props.minInputLength ?? 5))
            }
          >
            {i18n.translate("CASE_ID_PICKER_Apply")}
          </Button>
          <Button onClick={cancelSelection}>
            {i18n.translate("CASE_ID_PICKER_Cancel")}
          </Button>
        </div>
      </div>
    </FilterWrapper>
  );
};

export default CaseIdPicker;
