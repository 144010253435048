import { Divider, List, useMediaQuery, useTheme } from "@material-ui/core";
import {
  useState,
  useEffect,
  useMemo,
  Dispatch,
  SetStateAction,
  useCallback,
} from "react";
import { useSelector } from "react-redux";
import { INavigationOption } from "../../state/types/UserProfile";
import { NavigationOptionsSelector } from "./state/selectors";
import { NavigationPanelStyles } from "./styles/navigationPanelStyles";
import { useLocation } from "react-router-dom";
import { formatUserNavigationOptions } from "./utils";
import SingleNavOption from "./components/singleNavOption";
import ExpandableNavOption from "./components/expandableNavOption";

import {
  AppealsFeatureActive,
  ConversationsFeatureActive,
} from "../inboxViews/state/selectors";
import { trackHubUsageApi } from "../../state/api";
import { useUserId } from "../../state/hooks/clients/useUserId";
import { i18n } from "../../localizations";
import { useIsAdmin } from "../../state/hooks/clients/useIsAdmin";
import React from "react";

type props = {
  setShowFilters: Dispatch<SetStateAction<boolean>>;
  showFilters: boolean;
  isOpen: boolean;
  handleDrawerClose: () => void;
  setPageTitle: (string: string) => void;
};
const NavigationDrawer = (props: props) => {
  const classes = NavigationPanelStyles();
  const theme = useTheme();
  const userId = useUserId();
  const isAdmin = useIsAdmin();
  const isMobileView = useMediaQuery(theme.breakpoints.down("md"));

  const userNavigationOptions = useSelector(NavigationOptionsSelector);
  const appealsFeatureActive = useSelector(AppealsFeatureActive);
  const conversationsFeatureActive = useSelector(ConversationsFeatureActive);

  const location = useLocation();

  const navigationOptions = useMemo(
    () =>
      formatUserNavigationOptions({
        userNavigationOptions,
        appealsFeatureActive,
        conversationsFeatureActive,
      }),
    [userNavigationOptions, appealsFeatureActive, conversationsFeatureActive]
  );

  const [currentParentNav, setCurrentParentNav] = useState<
    INavigationOption | undefined
  >();
  const [currentChildNav, setCurrentChildNav] = useState<
    INavigationOption | undefined
  >();
  const [selectedNavigationAccordion, setSelectedNavigationAccordion] =
    useState<INavigationOption | undefined>();
  useEffect(() => {
    if (!currentParentNav) {
      const currentSelection = userNavigationOptions.find(
        (navOption) => navOption.path === location.pathname.toLowerCase()
      );

      const currentParent = navigationOptions.find((navOption) => {
        return (
          navOption.name === currentSelection?.parent ||
          navOption.name === currentSelection?.name
        );
      });

      const currentChildNav = currentParent?.children.find(
        (navOption) => location.pathname === navOption.path
      );

      const defaultSelectedNavigation = navigationOptions.find(
        (navigationOption) => navigationOption.name === "Inbox"
      );
      currentParent
        ? setCurrentParentNav(currentParent)
        : setCurrentParentNav(defaultSelectedNavigation);
      currentParent
        ? setSelectedNavigationAccordion(currentParent)
        : setSelectedNavigationAccordion(defaultSelectedNavigation);
      if (currentChildNav) {
        setCurrentChildNav(currentChildNav);
      }
    }
  }, []);

  const [reportPageName, setReportPageName] = useState<string>("");

  const handlePageTitle = useCallback(() => {
    if (currentParentNav?.name === "Inbox") {
      setReportPageName("");
    }
    const parentName = currentParentNav?.navigationName[i18n.locale as string];
    const childName = currentChildNav?.navigationName[i18n.locale as string]
      ? ` > ${currentChildNav?.navigationName[i18n.locale as string]}`
      : "";
    const reportPageTitle = reportPageName ? ` > ${reportPageName}` : "";

    props.setPageTitle(`${parentName}${childName}${reportPageTitle}`);
  }, [currentParentNav, currentChildNav, reportPageName]);

  useEffect(() => {
    handlePageTitle();
  }, [currentParentNav, currentChildNav, reportPageName]);

  const handleParentNavigationChange = (navOption: INavigationOption) => {
    setCurrentParentNav(navOption);
    setCurrentChildNav(undefined);
    if (selectedNavigationAccordion?.name !== navOption.name) {
      setSelectedNavigationAccordion(undefined);
    }
  };
  useEffect(() => {
    if (userId && !isAdmin) {
      if (currentChildNav) {
        trackHubUsageApi(userId, currentChildNav.path);
      }
      if (currentParentNav && currentParentNav.path) {
        trackHubUsageApi(userId, currentParentNav.path);
      }
    }
  }, [currentParentNav, currentChildNav]);

  const createNavigation = () => {
    return navigationOptions.map((navOption) => {
      if (navOption.children.length < 1 && navOption.path) {
        return (
          <React.Fragment key={navOption.id || navOption.name}>
            <SingleNavOption
              key={navOption.id || navOption.name}
              navOption={navOption}
              currentParentNav={currentParentNav}
              currentChildNav={currentChildNav}
              handleNavigationChange={handleParentNavigationChange}
              isMobile={isMobileView}
              setDrawerOpen={props.handleDrawerClose}
              isChild={false}
              setReportPageName={setReportPageName}
            />
            <Divider light={true} />
          </React.Fragment>
        );
      }
      if (navOption.children.length > 0) {
        return (
          <React.Fragment key={navOption.id || navOption.name}>
            <ExpandableNavOption
              navOption={navOption}
              setCurrentParentNav={setCurrentParentNav}
              currentParentNav={currentParentNav}
              currentChildNav={currentChildNav}
              setCurrentChildNav={setCurrentChildNav}
              isMobile={isMobileView}
              setDrawerOpen={props.handleDrawerClose}
              selectedNavigationAccordion={selectedNavigationAccordion}
              setSelectedNavigationAccordion={setSelectedNavigationAccordion}
              setReportPageName={setReportPageName}
            />
            <Divider light={true} />
          </React.Fragment>
        );
      }
    });
  };

  return (
    <div className={`hub-no-print`}>
      <List
        component="ul"
        className={`${classes.navOptionList} ${classes.navContainer}`}
      >
        {createNavigation()}
      </List>
    </div>
  );
};

export default NavigationDrawer;
