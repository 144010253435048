import { Button, makeStyles, Theme, useTheme } from "@material-ui/core";
import { TreeItem, TreeView } from "@material-ui/lab";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import SimpleBar from "simplebar-react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import i18n from "../../../localizations/i18n";
import {
  IPeriodHierarchy,
  IPeriodHierarchyOption,
} from "../../../state/types/FilterOptions";
import { GenericTreeViewStyles } from "../genericTreeView/styles/genericTreeViewStyles";
import { GenericTreeViewNodeStyles } from "../genericTreeView/styles/genericTreeViewNodeStyles";
import { VisitDateSelectionType } from "../../../state/types/FilterSets";
import dayjs, { ManipulateType } from "dayjs";
import { useCalculateTotalDaysPeriodHierarchies } from "./utils/calculateTotalDays";

interface IProps {
  availablePeriods: IPeriodHierarchyOption[];
  selectedPeriods: number[];
  expandedPeriods: string[] | null;
  onCancel: () => void;
  onApply: (
    selection: number[],
    selectionType: VisitDateSelectionType,
    expandedNodes: string[]
  ) => void;
  maxDays: number | undefined;
  setShowMaxPeriodError?: Dispatch<SetStateAction<boolean>>;
}

interface INodeSearch {
  id: number | undefined;
  parentIds: number[];
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  brandDivider: {
    padding: theme.spacing(1),
    display: "block",
    marginBottom: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
  },
  container: {
    padding: theme.spacing(1),
  },
}));

const PeriodHierarchyPicker = (props: IProps) => {
  const treeClasses = GenericTreeViewStyles();
  const treeNodeClasses = GenericTreeViewNodeStyles();
  const theme = useTheme();
  const classes = useStyles(theme);
  const calculateTotalDays = useCalculateTotalDaysPeriodHierarchies(
    props.availablePeriods,
    props.maxDays
  );

  const earlyDateRange =
    process.env.REACT_APP_PERIOD_HIERARCHY_EARLY_DATE_RANGE;

  const earlyDateRangeType = process.env
    .REACT_APP_PERIOD_HIERARCHY_EARLY_DATE_RANGE_TYPE as ManipulateType;

  const dateComparisonToday = dayjs();
  const dateComparisonEarliestDate = dayjs().subtract(
    Number.parseInt(earlyDateRange ? earlyDateRange : "25"),
    earlyDateRangeType ? earlyDateRangeType : "months"
  );

  const [expandedPeriods, setExpandedPeriods] = useState<string[]>([]);
  const [selectedPeriods, setSelectedPeriods] = useState<number[]>(
    props.selectedPeriods
  );
  const [disabledPeriods, setDisabledPeriods] = useState<number[]>([]);

  useEffect(() => {
    if (!props.maxDays) return;

    const newDisabledPeriods: number[] = [];

    const allPeriods: IPeriodHierarchy[] = [];
    props.availablePeriods.forEach((group) =>
      flattenPeriodHierarchy(group.periods, allPeriods)
    );

    allPeriods.forEach((period) => {
      const tempSelectedPeriods = [...selectedPeriods];

      if (!tempSelectedPeriods.includes(period.id)) {
        tempSelectedPeriods.push(period.id);
      }

      const totalDays = calculateTotalDays(tempSelectedPeriods);

      if (totalDays > props.maxDays!) {
        newDisabledPeriods.push(period.id);
      }
    });

    if (newDisabledPeriods.length === 0) {
      props.setShowMaxPeriodError?.(false);
    }

    setDisabledPeriods(newDisabledPeriods);
  }, [selectedPeriods, props.availablePeriods, props.maxDays]);

  const flattenPeriodHierarchy = (
    periods: IPeriodHierarchy[],
    accumulator: IPeriodHierarchy[]
  ) => {
    periods.forEach((period) => {
      accumulator.push(period);
      if (period.children && period.children.length > 0) {
        flattenPeriodHierarchy(period.children, accumulator);
      }
    });
  };

  useEffect(() => {
    if (!props.maxDays) return;

    if (calculateTotalDays(selectedPeriods) > props.maxDays) {
      const allPeriods: IPeriodHierarchy[] = [];
      props.availablePeriods.forEach((group) => {
        flattenPeriodHierarchy(group.periods, allPeriods);
      });
      const allPeriodIds = allPeriods.map((p) => p.id);

      const newSelectedPeriods: number[] = [];

      for (const periodId of allPeriodIds) {
        if (
          calculateTotalDays([...newSelectedPeriods, periodId]) <= props.maxDays
        ) {
          newSelectedPeriods.push(periodId);
        } else {
          break;
        }
      }

      setSelectedPeriods(newSelectedPeriods);
    }
  }, [props.selectedPeriods]);

  const getAllExpandablePeriodIds = (
    ids: number[],
    periods: IPeriodHierarchy[]
  ) => {
    if (periods && periods.length > 0) {
      periods.forEach((x) => {
        if (x.children && x.children.length > 0) {
          ids.push(x.id);
          ids = getAllExpandablePeriodIds(ids, x.children);
        }
      });
    }

    return ids;
  };

  const getAllRelatedPeriodIds = (
    ids: number[],
    periods: IPeriodHierarchy[]
  ): number[] => {
    if (periods && periods.length > 0) {
      periods.forEach((x) => {
        if (x.children && x.children.length > 0) {
          ids = getAllRelatedPeriodIds(ids, x.children);
        } else if (
          dateComparisonToday.isAfter(x.startDate) &&
          dateComparisonEarliestDate.isBefore(x.endDate)
        ) {
          ids.push(x.id);
        }
      });
    }

    return ids;
  };

  const getCurrentDefaultPeriod = (
    periods: IPeriodHierarchy[]
  ): INodeSearch => {
    let result: INodeSearch = { id: undefined, parentIds: [] };

    periods.forEach((x) => {
      if (
        !result.id &&
        x.isDefault &&
        dateComparisonToday.isAfter(x.startDate) &&
        dateComparisonToday.isBefore(x.endDate)
      ) {
        result.id = x.id;
      } else if (!result.id && x.children.length > 0) {
        result = getCurrentDefaultPeriod(x.children);
        if (result.id) {
          result.parentIds.push(x.id);
        }
      }
    });

    return result;
  };

  const handleNodeChecked = (
    event: React.ChangeEvent<HTMLInputElement>,
    period: IPeriodHierarchy
  ) => {
    let allRelatedEventPeriods: number[] = [];
    if (period.children && period.children.length > 0) {
      allRelatedEventPeriods = getAllRelatedPeriodIds([], period.children);
    } else {
      allRelatedEventPeriods = [period.id];
    }

    let newSelectedPeriods = [...selectedPeriods];

    if (event.target.checked) {
      const newTotalDays = calculateTotalDays([
        ...newSelectedPeriods,
        ...allRelatedEventPeriods,
      ]);

      if (!props.maxDays || newTotalDays <= props.maxDays) {
        newSelectedPeriods = [
          ...new Set(newSelectedPeriods.concat(allRelatedEventPeriods)),
        ];
      } else {
        props.setShowMaxPeriodError?.(true);
        return;
      }
    } else {
      newSelectedPeriods = newSelectedPeriods.filter(
        (x) => allRelatedEventPeriods.indexOf(x) < 0
      );
    }

    setSelectedPeriods(newSelectedPeriods);
  };

  const handleNodeToggle = (
    event: React.ChangeEvent<any>,
    nodeIds: string[]
  ) => {
    if (
      event.target?.type !== "checkbox" &&
      event.target?.tagName !== "SPAN" &&
      event.target?.tagName !== "LABEL"
    ) {
      setExpandedPeriods(nodeIds);
    }
  };

  const getSelectionText = () => {
    return `${selectedPeriods.length} ${i18n.translate(
      "DATE_PERIOD_PICKER_Weeks_Selected"
    )}`;
  };

  const handleSelectAll = () => {
    const allPeriodIds: number[] = [];
    props.availablePeriods.forEach((x) => {
      allPeriodIds.concat(getAllRelatedPeriodIds(allPeriodIds, x.periods));
    });

    const newSelectedPeriods = [...selectedPeriods];

    for (const period of allPeriodIds) {
      if (
        (!props.maxDays ||
          calculateTotalDays([...newSelectedPeriods, period]) <=
            props.maxDays) &&
        !selectedPeriods.includes(period)
      ) {
        newSelectedPeriods.push(period);
      } else if (selectedPeriods.includes(period)) {
        continue;
      }
    }

    setSelectedPeriods(newSelectedPeriods);
  };

  const handleClearSelections = () => {
    setSelectedPeriods([]);
    props.setShowMaxPeriodError?.(false);
  };

  const handleExpandAll = () => {
    const allPeriodIds: number[] = [];
    props.availablePeriods.forEach((x) => {
      allPeriodIds.concat(getAllExpandablePeriodIds(allPeriodIds, x.periods));
    });
    setExpandedPeriods(allPeriodIds.map((x) => x.toString()));
  };

  const handleCollapseAll = () => {
    setExpandedPeriods([]);
  };

  const handleCancel = () => {
    props.onCancel();
  };

  const handleApply = () => {
    if (selectedPeriods.length === 0) {
      const allPeriodIds: number[] = [];
      props.availablePeriods.forEach((x) => {
        allPeriodIds.concat(getAllRelatedPeriodIds(allPeriodIds, x.periods));
      });

      props.onApply(
        allPeriodIds,
        VisitDateSelectionType.reportingPeriods,
        expandedPeriods
      );
    } else {
      props.onApply(
        selectedPeriods,
        VisitDateSelectionType.reportingPeriods,
        expandedPeriods
      );
    }
  };

  const buildNodes = (periodHierarchy: IPeriodHierarchy): JSX.Element => {
    return (
      <TreeItem
        key={periodHierarchy.id}
        nodeId={periodHierarchy.id.toString()}
        label={buildNodeContent(periodHierarchy)}
      >
        {periodHierarchy.children
          .filter(
            (x) =>
              dateComparisonToday.isAfter(x.startDate) &&
              dateComparisonEarliestDate.isBefore(x.endDate)
          )
          .map((x) => buildNodes(x))}
      </TreeItem>
    );
  };

  const buildNodeContent = (periodHierarchy: IPeriodHierarchy): JSX.Element => {
    const allChldNodes = getAllRelatedPeriodIds([], periodHierarchy.children);
    const selectedChildNodes = selectedPeriods.filter(
      (x) => allChldNodes.indexOf(x) > -1
    );

    const checked =
      selectedPeriods.find((x) => x === periodHierarchy.id) !== undefined;

    const partiallyChecked =
      selectedChildNodes.length > 0 &&
      selectedChildNodes.length !== allChldNodes.length;

    const allChildrenChecked =
      selectedChildNodes.length > 0 &&
      selectedChildNodes.length === allChldNodes.length;

    const checkmarkClass = partiallyChecked ? "partial-check" : "";

    const isDisabled =
      disabledPeriods.includes(periodHierarchy.id) ||
      (periodHierarchy.children &&
        periodHierarchy.children.some((child) =>
          disabledPeriods.includes(child.id)
        ));

    const nodeTooltip =
      `${dayjs(periodHierarchy.startDate).format(
        i18n.translate("DATE_PERIOD_PICKER_Tooltip_Date_Format")
      )} -> ${dayjs(periodHierarchy.endDate).format(
        i18n.translate("DATE_PERIOD_PICKER_Tooltip_Date_Format")
      )}` +
      (isDisabled
        ? i18n.translate("DATE_PERIOD_PICKER_Tooltip_Exceeded_Limit")
        : "");

    return (
      <label
        className={`${treeNodeClasses.treeItemContent} ${
          isDisabled ? "disabled-node" : ""
        }`}
        title={nodeTooltip}
      >
        <input
          type="checkbox"
          value={periodHierarchy.id}
          name={periodHierarchy.name}
          onChange={(e) => handleNodeChecked(e, periodHierarchy)}
          checked={checked || allChildrenChecked}
          onClick={() =>
            isDisabled ? props.setShowMaxPeriodError?.(true) : null
          }
          className={isDisabled ? "disabled" : ""}
        />
        <span
          className={`${treeNodeClasses.checkmark} ${checkmarkClass}`}
        ></span>
        {periodHierarchy.name}
      </label>
    );
  };

  useEffect(() => {
    if (props.expandedPeriods && props.expandedPeriods.length > 0) {
      setExpandedPeriods(props.expandedPeriods);
    } else if (props.availablePeriods.length === 1) {
      const currentPeriod = getCurrentDefaultPeriod(
        props.availablePeriods[0].periods
      );
      if (currentPeriod && currentPeriod.id) {
        setExpandedPeriods(currentPeriod.parentIds.map((x) => x.toString()));
      }
    }
  }, [props.expandedPeriods, props.availablePeriods]);

  return (
    <div className={treeNodeClasses.container}>
      <SimpleBar className={treeNodeClasses.treeContainer}>
        <TreeView
          defaultCollapseIcon={
            <ExpandMoreIcon className={treeNodeClasses.expandIcons} />
          }
          defaultExpandIcon={<ChevronRightIcon />}
          disableSelection={true}
          onNodeToggle={handleNodeToggle}
          expanded={expandedPeriods}
          selected={selectedPeriods.map((x) => x.toString())}
        >
          {(props.availablePeriods.length === 1 && (
            <div className={classes.container}>
              {props.availablePeriods[0].periods
                .filter(
                  (x) =>
                    dateComparisonToday.isAfter(x.startDate) &&
                    dateComparisonEarliestDate.isBefore(x.endDate)
                )
                .map((p) => buildNodes(p))}
            </div>
          )) ||
            props.availablePeriods.map((x) => {
              const displayName =
                x.brandName ??
                i18n.translate("DATE_PERIOD_PICKER_Undefined_Brand_Name");
              const key = x.brandId ?? 0;

              return (
                <div key={key} className={classes.container}>
                  <span className={classes.brandDivider}>{displayName} hi</span>
                  {x.periods
                    .filter((x) => dateComparisonToday.isAfter(x.startDate))
                    .map((p) => buildNodes(p))}
                </div>
              );
            })}
        </TreeView>
      </SimpleBar>

      <div className={treeClasses.subTasks}>
        <Button size="small" onClick={handleExpandAll}>
          {i18n.translate("GENERIC_TREE_VIEW_ExpandAll")}
        </Button>
        <Button size="small" onClick={handleCollapseAll}>
          {i18n.translate("GENERIC_TREE_VIEW_Collapse_All")}
        </Button>
        <Button size="small" onClick={handleSelectAll}>
          {i18n.translate("GENERIC_TREE_VIEW_Select_All")}
        </Button>
        <Button size="small" onClick={handleClearSelections}>
          {i18n.translate("GENERIC_TREE_VIEW_Clear_Selections")}
        </Button>
      </div>

      <div className={treeClasses.actions}>
        <div className={treeClasses.status}>{getSelectionText()}</div>
        <Button onClick={handleApply} variant="contained" color="primary">
          {i18n.translate("GENERIC_TREE_VIEW_Apply")}
        </Button>
        <Button onClick={handleCancel}>
          {i18n.translate("GENERIC_TREE_VIEW_Cancel")}
        </Button>
      </div>
    </div>
  );
};

export default PeriodHierarchyPicker;
