import { LinearProgress } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  deleteAppealAttachmentApi,
  postAppealAddCommentApi,
  postAppealApprovedForReviewApi,
  postAppealCancelledApi,
  postAppealChangeAppealReviewDecisionApi,
  postAppealEditApi,
  postAppealGrantedApi,
  postAppealUnderReviewApi,
  postAppealUploadAttachmentApi,
  postGetAppealApi,
} from "../../state/api/Appeals-API";
import { useClientId } from "../../state/hooks/clients/useClientId";
import { useIsAdmin } from "../../state/hooks/clients/useIsAdmin";
import { useUserId } from "../../state/hooks/clients/useUserId";
import { useUserName } from "../../state/hooks/clients/useUserName";
import {
  ICaseDetails,
  ICaseEvent,
  WorkflowStatus,
} from "../../state/types/TaskCentreCases";
import { getUrlQueryValueNumber } from "../../utils/urlParser";
import CaseEventList from "../taskCentreEvents/components/caseEventList";
import {
  AllManagers,
  CurrentUserId,
} from "../taskCentreEvents/state/selectors";
import CommonCommentField from "../_common/comments/components/commonCommentField";
import { AppealsStyles } from "./styles";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { AxiosResponse } from "axios";
import {
  NewAppeal,
  ManagerApproval,
  AppealsTriage,
  AppealClosed,
  AppealAttachments,
} from "./components/index";
import { RecategoriseCaseCommand } from "../../state/types/TaskCentreCommands";
import { useDispatch } from "react-redux";
import { executeCaseCommand } from "../../state/actions/TaskCentre-CaseList-Actions";
import { AppealAction } from "./types/appealAction";
import { useApprovalManagers } from "../../state/hooks/appeals/useApprovalManagers";
import { i18n } from "../../localizations";
import { AttachmentFileSelector } from "../inboxViews/components/attachments";
import IsAuthorised from "../../authorisation/permissions";
import { PermissionType } from "../../authorisation/permissionsUtils";

interface IProps {
  visitId: number | undefined;
}

const InboxAppeals = (props: IProps) => {
  const dispatch = useDispatch();
  const appealsClasses = AppealsStyles();

  const clientId = useClientId();
  const userId = useUserId();
  const userName = useUserName();
  const isAdmin = useIsAdmin();

  const currentUserId = useSelector(CurrentUserId);
  const managers = useSelector(AllManagers);

  const [currentAppeal, setCurrentAppeal] = useState<
    ICaseDetails | undefined
  >();

  const [isLoadingAppeal, setIsLoadingAppeal] = useState<boolean>(false);
  const [isPendingAction, setIsPendingAction] = useState<boolean>(false);
  const [canManageAppeals, setCanManageAppeals] = useState<boolean | undefined>(
    undefined
  );

  const checkUserCanManageAppeals = async () => {
    const canManageAppeals = await IsAuthorised(PermissionType.ManageAppeals);
    setCanManageAppeals(canManageAppeals || isAdmin);
  };

  useEffect(() => {
    checkUserCanManageAppeals();
  }, []);

  const [pendingAttachments, setPendingAttachments] = useState<File[]>([]);

  useEffect(() => {
    if (currentAppeal) {
      setPendingAttachments([]);
    }
  }, [currentAppeal]);

  useEffect(() => {
    if (canManageAppeals !== undefined && clientId && props.visitId) {
      setIsLoadingAppeal(true);
      postGetAppealApi(clientId, props.visitId, canManageAppeals).then(
        (response) => {
          setCurrentAppeal(response);
          setIsLoadingAppeal(false);
        }
      );
    }
  }, [setCurrentAppeal, clientId, props.visitId, canManageAppeals]);

  const history = useHistory();

  const queryAppealActionOnLoad = getUrlQueryValueNumber("appealaction");

  const [appealTaskOnLoad, setAppealActionOnLoad] = useState<
    AppealAction | undefined
  >(queryAppealActionOnLoad);

  const [eventsWithComments, setEventsWithComments] = useState<ICaseEvent[]>(
    []
  );
  const [comments, setComments] = useState<string | undefined>();

  useEffect(() => {
    if (appealTaskOnLoad === undefined) {
      const currentQueryParameters = queryString.parse(window.location.search);
      const newQueryParameters = {
        ...currentQueryParameters,
        appealaction: undefined,
      };
      history.push({ search: queryString.stringify(newQueryParameters) });
    } else if (currentAppeal) {
      if (
        currentAppeal.wfStatus === WorkflowStatus.AppealRaised &&
        userId === currentAppeal.assigneeId
      ) {
        let actionPromise: Promise<AxiosResponse<ICaseDetails>> | undefined =
          undefined;

        setIsPendingAction(true);

        if (appealTaskOnLoad === AppealAction.Approve) {
          actionPromise = postAppealApprovedForReviewApi(
            clientId,
            currentAppeal.id,
            comments ?? "",
            userId
          );
        }

        if (appealTaskOnLoad === AppealAction.Cancel) {
          actionPromise = postAppealCancelledApi(
            clientId,
            currentAppeal.id,
            comments ?? "",
            userId
          );
        }

        actionPromise?.then((response) => {
          setAppealActionOnLoad(undefined);
          setCurrentAppeal(response.data);
          setIsPendingAction(false);
        });
      }
    }
  }, [appealTaskOnLoad, currentAppeal, userId, clientId, comments, history]);

  useEffect(() => {
    if (currentAppeal !== undefined) {
      setEventsWithComments(
        currentAppeal.events
          .sort((x) => x.id)
          .filter((x) => x.comment && x.comment.length > 0)
      );
    } else {
      setEventsWithComments([]);
    }

    setComments("");
  }, [props.visitId, currentAppeal, userId, userName]);

  const approvalManagers = useApprovalManagers({
    clientId: clientId,
    visitId: props.visitId,
  });

  const [isApprovalManager, setIsApprovalManager] = useState<boolean>(true);
  useEffect(() => {
    const isApprovalManager =
      approvalManagers.length > 0
        ? approvalManagers.some((x) => x.id === userId)
        : true;
    setIsApprovalManager(isApprovalManager);
  }, [approvalManagers, userId]);

  const postNewCategory = (value: number): void => {
    if (currentAppeal) {
      const categoryCommand = new RecategoriseCaseCommand(
        currentAppeal.id,
        value
      );
      dispatch(
        executeCaseCommand(currentAppeal.id, [categoryCommand], () => {
          if (clientId && props.visitId) {
            postGetAppealApi(clientId, props.visitId, canManageAppeals).then(
              (response) => {
                setCurrentAppeal(response);
              }
            );
          }
        })
      );
    }
  };

  const postNewAppealStatus = (value: number): void => {
    if (currentAppeal) {
      postAppealChangeAppealReviewDecisionApi(
        clientId,
        currentAppeal.id,
        comments ?? "",
        value
      ).then((response) => setCurrentAppeal(response.data));
    }
  };

  const setAttachment = (file: File) => {
    if (!currentAppeal) {
      setPendingAttachments([...pendingAttachments, file]);
    } else {
      postAppealUploadAttachmentApi(
        clientId,
        currentAppeal.id,
        isAdmin ? undefined : userId,
        file,
        canManageAppeals ?? false
      ).then((response) => setCurrentAppeal(response.data));
    }
  };

  const deleteAttachment = async (caseEventAttachmentId: number) => {
    if (currentAppeal) {
      deleteAppealAttachmentApi(
        clientId,
        currentAppeal.vId,
        currentAppeal.id,
        isAdmin ? undefined : userId,
        caseEventAttachmentId,
        canManageAppeals ?? false
      ).then((response) => setCurrentAppeal(response.data));
    } else {
      setPendingAttachments(
        pendingAttachments.filter((_, i) => i !== caseEventAttachmentId)
      );
      Promise.resolve();
    }
  };

  if (isLoadingAppeal || isPendingAction) {
    return <LinearProgress color="primary" />;
  }

  return (
    <div className={`${appealsClasses.flexColumn}`}>
      {!currentAppeal && (
        <NewAppeal
          clientId={clientId}
          visitId={props.visitId}
          userId={userId}
          setIsPendingAction={setIsPendingAction}
          setCurrentAppeal={setCurrentAppeal}
          isApprovalManager={isApprovalManager}
          userName={userName}
          managers={managers}
          pendingAttachments={pendingAttachments}
        />
      )}
      {currentAppeal &&
        currentAppeal.wfStatus === WorkflowStatus.AppealRaised && (
          <ManagerApproval
            currentAppeal={currentAppeal}
            isPendingAction={isPendingAction}
            setIsPendingAction={setIsPendingAction}
            postApprovedForReview={() =>
              postAppealApprovedForReviewApi(
                clientId,
                currentAppeal.id,
                comments ?? "",
                userId
              ).then((response) => setCurrentAppeal(response.data))
            }
            postCancelledAppeal={() =>
              postAppealCancelledApi(
                clientId,
                currentAppeal.id,
                comments ?? "",
                userId
              ).then((response) => setCurrentAppeal(response.data))
            }
            approvalButtonText={i18n.translate("APPEALS_APPROVE_BUTTON_TEXT")}
            cancelButtonText={i18n.translate("APPEALS_REJECT_BUTTON_TEXT")}
            isApprovalManager={isApprovalManager}
            editAppeal={(params) =>
              postAppealEditApi({
                clientId,
                caseId: currentAppeal.id,
                ...params,
              }).then((response) => setCurrentAppeal(response.data))
            }
          />
        )}
      {currentAppeal &&
        currentAppeal.wfStatus === WorkflowStatus.AppealApprovedForReview && (
          <ManagerApproval
            currentAppeal={currentAppeal}
            isPendingAction={isPendingAction}
            setIsPendingAction={setIsPendingAction}
            postApprovedForReview={() =>
              postAppealUnderReviewApi(
                clientId,
                currentAppeal.id,
                comments ?? ""
              ).then((response) => setCurrentAppeal(response.data))
            }
            postCancelledAppeal={() =>
              postAppealCancelledApi(
                clientId,
                currentAppeal.id,
                comments ?? ""
              ).then((response) => setCurrentAppeal(response.data))
            }
            approvalButtonText={i18n.translate("APPEALS_REVIEWING")}
            cancelButtonText={i18n.translate("APPEALS_REJECT")}
            isApprovalManager={isApprovalManager}
            editAppeal={(params) =>
              postAppealEditApi({
                clientId,
                caseId: currentAppeal.id,
                ...params,
              }).then((response) => setCurrentAppeal(response.data))
            }
          />
        )}
      {currentAppeal &&
        currentAppeal.wfStatus === WorkflowStatus.AppealUnderReview &&
        canManageAppeals && (
          <AppealsTriage
            clientId={clientId}
            currentAppeal={currentAppeal}
            postCategory={postNewCategory}
            categoryId={currentAppeal.category?.id}
            postAppealDecision={postNewAppealStatus}
            appealDecisionId={currentAppeal.appealReviewDecision?.id}
            isPendingAction={isPendingAction}
            setIsPendingAction={setIsPendingAction}
            grantAppeal={(sendNotification) =>
              postAppealGrantedApi(
                clientId,
                currentAppeal.id,
                comments ?? "",
                sendNotification
              ).then((response) => {
                setCurrentAppeal(response.data);
                setIsPendingAction(false);
              })
            }
            isApprovalManager={isApprovalManager}
            editAppeal={(params) =>
              postAppealEditApi({
                clientId,
                caseId: currentAppeal.id,
                ...params,
              }).then((response) => setCurrentAppeal(response.data))
            }
          />
        )}
      {currentAppeal &&
        (currentAppeal.wfStatus === WorkflowStatus.AppealGranted ||
          currentAppeal.wfStatus === WorkflowStatus.AppealRejected ||
          currentAppeal.wfStatus === WorkflowStatus.AppealCancelled) && (
          <AppealClosed
            isAdmin={canManageAppeals ?? false}
            currentAppeal={currentAppeal}
            postApprovedForReview={() =>
              postAppealUnderReviewApi(
                clientId,
                currentAppeal.id,
                comments ?? ""
              ).then((response) => {
                setCurrentAppeal(response.data);
                setIsPendingAction(false);
              })
            }
            isPendingAction={isPendingAction}
            setIsPendingAction={setIsPendingAction}
          />
        )}
      {currentAppeal && (
        <>
          <CaseEventList
            visitId={currentAppeal.vId}
            caseId={currentAppeal.id}
            caseEvents={eventsWithComments}
            currentUserId={currentUserId ? currentUserId : 0}
            managers={managers}
          />
          <CommonCommentField
            fieldNotification={undefined}
            submitInternalOption={canManageAppeals}
            onSubmit={(commentText, internal) => {
              postAppealAddCommentApi(
                clientId,
                currentAppeal.id,
                commentText,
                internal,
                canManageAppeals ?? false,
                isAdmin ? undefined : userId
              ).then((response) => setCurrentAppeal(response.data));
            }}
          />
        </>
      )}
      <AttachmentFileSelector onFileSelected={setAttachment} />
      <AppealAttachments
        clientId={clientId}
        pendingAttachments={pendingAttachments}
        currentAppeal={currentAppeal}
        deleteAttachment={deleteAttachment}
        userId={userId}
        isAdmin={canManageAppeals ?? false}
      />
    </div>
  );
};

export default InboxAppeals;
