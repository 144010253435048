import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IHubFilter } from "../../state/types/FilterSets";
import { RootState } from "../../state/reducers/RootReducer";
import { InboxListStyles } from "./styles/inboxListStyles";
import {
  AllVisits,
  GetCurrentFilterSet,
  GetVisibleFilters,
  MoreVisitsAvailable,
  AllTaskCounts,
  SelectedVisitId,
  TotalVisitCount,
  VisitDetailsListError,
  VisitDetailsListPending,
  VisitsLastRefreshed,
  GetUserCurrentBrands,
} from "./state/selectors";
import { setCurrentFilterSetToDefault } from "../../state/actions/FilterSets-Actions";
import {
  clearVisitDetailsList,
  fetchVisitTasksCounts,
  fetchVisitDetailsList,
  setSelectedVisitDetailId,
} from "../../state/actions/VisitDetails-Actions";
import IsAuthorised from "../../authorisation/permissions";
import { PermissionType } from "../../authorisation/permissionsUtils";
import { clearVisitReportError } from "../../state/actions/VisitReport-Actions";
import BuildVisitDetailsListQuery from "./utils/visitDetailsListQueryBuilder";
import VisitListError from "./components/visitListError";
import VisitList from "./components/visitList";
import VisitListSkeleton from "./components/visitListSkeleton";
import VisitListEmpty from "./components/visitlistEmpty";
import InboxItem from "./components/inboxItem";
import { Skeleton } from "@material-ui/lab";
import { Button, Dialog, DialogContent } from "@material-ui/core";
import i18n from "../../localizations/i18n";
import dayjs from "dayjs";
import { ManagerCount } from "../inboxViews/state/selectors";
import { ClientBorderThreshold } from "../../state/api";
interface IProps {
  pageRef: string;
  visitTypes: string[];
  borderColorThresholds: ClientBorderThreshold | undefined;
  requestedVisitId?: number | undefined;
  listHeightOffset?: number;
  showAsPopupList?: boolean;
}

const InboxList = (props: IProps) => {
  const classes = InboxListStyles();

  const dispatch = useDispatch();

  const [popupListOpen, setPopupListOpen] = useState(false);
  const [visitTypes, setVisitTypes] = useState(props.visitTypes);
  const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
  const [filterState, setFilterState] = useState<IHubFilter[]>([]);
  const [userCanReadGuestDetails, setUserCanReadGuestDetails] = useState(false);

  const currentFilterSet = useSelector(GetCurrentFilterSet);
  const usersCurrentBrands = useSelector(GetUserCurrentBrands);
  const filters = useSelector((state: RootState) =>
    GetVisibleFilters(state, props.pageRef)
  );

  const visits = useSelector(AllVisits);
  const selectedVisitId = useSelector(SelectedVisitId);
  const visitsTotalCount = useSelector(TotalVisitCount);
  const visitsLastRefreshed = useSelector(VisitsLastRefreshed);
  const moreVisitsAvailable = useSelector(MoreVisitsAvailable);
  const visitsPending = useSelector(VisitDetailsListPending);
  const visitsError = useSelector(VisitDetailsListError);
  const actionCounts = useSelector(AllTaskCounts);
  const managerCount = useSelector(ManagerCount);

  const applyVisitsSelection = (visitId: number): void => {
    if (selectedVisitId) {
      dispatch(fetchVisitTasksCounts([selectedVisitId]));
    }

    if (visitId && visitId > 0) {
      setPopupListOpen(false);
      dispatch(setSelectedVisitDetailId(visitId));
      dispatch(clearVisitReportError());
    }
  };

  const loadAdditionalVisits = (): void => {
    const query = BuildVisitDetailsListQuery(
      visitsTotalCount,
      filters,
      props.visitTypes,
      selectedBrands,
      undefined,
      undefined
    );

    dispatch(fetchVisitDetailsList(query));
  };

  useEffect(() => {
    const checkUserCanReadGuestDetails = async () => {
      const userCanReadDetails = await IsAuthorised(
        PermissionType.ReadGuestDetails
      );
      setUserCanReadGuestDetails(userCanReadDetails);
    };

    checkUserCanReadGuestDetails();
  });

  useEffect(() => {
    if (!currentFilterSet) {
      dispatch(setCurrentFilterSetToDefault(props.pageRef));
    }
  }, [currentFilterSet, dispatch, props.pageRef]);

  const [appDataLoaded, setAppDataLoaded] = useState(false);

  useEffect(() => {
    if (managerCount !== 0) {
      setAppDataLoaded(true);
    }
  }, [managerCount]);

  useEffect(() => {
    if (
      appDataLoaded &&
      currentFilterSet &&
      (visitsLastRefreshed === undefined ||
        filterState !== filters ||
        visitTypes !== props.visitTypes ||
        selectedBrands !== usersCurrentBrands)
    ) {
      if (filterState.length === 0 || filterState.length >= filters.length) {
        dispatch(clearVisitDetailsList());

        const query = BuildVisitDetailsListQuery(
          0,
          filters,
          props.visitTypes,
          usersCurrentBrands,
          props.requestedVisitId,
          undefined
        );

        setVisitTypes(props.visitTypes);
        setSelectedBrands(usersCurrentBrands);
        dispatch(fetchVisitDetailsList(query));
      }
      setFilterState(filters);
    }

    if (visits && visits.length > 0 && selectedVisitId === undefined) {
      dispatch(setSelectedVisitDetailId(visits[0].id));
    }
  }, [
    appDataLoaded,
    currentFilterSet,
    dispatch,
    filterState,
    filters,
    props.requestedVisitId,
    props.visitTypes,
    selectedVisitId,
    visitTypes,
    visits,
    visitsLastRefreshed,
    selectedBrands,
    usersCurrentBrands,
  ]);

  const refs = props.showAsPopupList
    ? visits.reduce((acc: any, value) => {
        acc[value.id] = React.createRef();
        return acc;
      }, {} as any)
    : ({} as any);

  const scrollToSelected = (): void => {
    if (selectedVisitId && refs) {
      refs[selectedVisitId].current.scrollIntoView({
        behavior: "auto",
        block: "start",
      });
    }
  };

  const visitList = (): JSX.Element => {
    return (
      <>
        {visitsError && <VisitListError />}
        {!visitsError && appDataLoaded && props.borderColorThresholds && (
          <VisitList
            fetchMoreVisits={loadAdditionalVisits}
            moreVisitsAvailable={moreVisitsAvailable}
            visits={visits}
            isLoading={visitsPending}
            skeleton={<VisitListSkeleton />}
            visitView={(visitDetails) => {
              return (
                <div ref={refs[visitDetails.id]} key={visitDetails.id}>
                  <InboxItem
                    visitDetails={visitDetails}
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    borderColorThresholds={props.borderColorThresholds!}
                    onItemSelected={applyVisitsSelection}
                    isSelected={visitDetails.id === selectedVisitId}
                    tasksCount={
                      actionCounts.find((x) => x.visitId === visitDetails.id)
                        ?.count
                    }
                    showGuestContact={userCanReadGuestDetails}
                  />
                </div>
              );
            }}
            emptyVisits={<VisitListEmpty />}
          />
        )}
      </>
    );
  };

  const popupSelectorLabel = () => {
    let labelText = i18n.translate("VISIT_DETAILS_LIST_NoVisitSelected");

    if (!visitsPending && selectedVisitId) {
      const selectedVisit = visits.find((x) => x.id === selectedVisitId);
      if (selectedVisit) {
        labelText = `${selectedVisit.branch} - ${dayjs(
          selectedVisit.date
        ).format(i18n.translate("VISIT_DETAILS_LIST_Date_Format"))} - ${
          selectedVisit.score
        }%`;
      }
    }

    return labelText;
  };

  const visitListInitialised = !visitsPending || visits.length !== 0;

  return (
    <div className={classes.listContainer}>
      {props.showAsPopupList && visitListInitialised && (
        <Button
          className={classes.popupSelection}
          onClick={() => setPopupListOpen(true)}
        >
          <>
            <span>
              {i18n.translate("VISIT_DETAILS_LIST_Select_Visit")}
              <div className={classes.popupSelectionHint}>
                {popupSelectorLabel()}
              </div>
            </span>
          </>
        </Button>
      )}

      {props.showAsPopupList &&
        !visitListInitialised &&
        !appDataLoaded &&
        props.borderColorThresholds === undefined && (
          <>
            <Skeleton
              className={classes.loader}
              variant="rect"
              height={40}
              animation="wave"
            />
          </>
        )}

      {props.showAsPopupList && (
        <Dialog
          className={classes.popupSelectionDialog}
          fullWidth
          onClose={() => setPopupListOpen(false)}
          onEntered={() => scrollToSelected()}
          open={popupListOpen}
        >
          <DialogContent>{visitList()}</DialogContent>
        </Dialog>
      )}

      {!props.showAsPopupList && visitList()}
    </div>
  );
};

export default InboxList;
