import React, { createContext, useEffect, useState } from "react";

import clsx from "clsx";
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import PreLoad from "../../components/preLoad/preload";
import SessionManager from "../../components/sessionManager/sessionManager";
import PageContainer from "../../components/pageContainer/pageContainer";
import NavigationDrawer from "../../components/navigationPanel/NavigationDrawer";
import UserToolbar from "../../components/userToolbar/userToolbar";
import { hgemColours } from "../../themes/defaultTheme";
import { useMediaQuery } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import { routes } from "../../navigation/routes";
const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      height: "100%",
      flexDirection: "column",
      overflowX: "clip",
      boxSizing: "border-box",
      [theme.breakpoints.down("xs")]: {
        paddingRight: "3%",
        transition: theme.transitions.create(["padding"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
    },
    appBar: {
      boxShadow: "none",
      paddingRight: "1%",
      backgroundColor: hgemColours.OffWhite,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: theme.hubSettings.navigation.backgroundColor,
      background: theme.hubSettings.navigation.background,
      overflowX: "clip",
      [theme.breakpoints.down(theme.breakpoints.values.xs)]: {
        width: "100vw",
      },
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
    content: {
      flexGrow: 1,
      backgroundColor: hgemColours.OffWhite,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      display: "flex",
      flexDirection: "column",
      width: "100vw",
      height: "100%",
      minHeight: 0,
      [theme.breakpoints.down(theme.breakpoints.values.xs)]: {
        overflowY: "scroll",
        height: "80vh",
      },
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: drawerWidth,
      width: `calc(100vw - ${drawerWidth}px)`,
    },
    toolBar: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
    },
    clientLogo: {
      width: "100%",
      boxShadow: "none",
      backgroundColor: "transparent",
      minHeight: theme.hubSettings.logo.minHeight,
      backgroundPosition: theme.hubSettings.logo.position,
      backgroundSize: theme.hubSettings.logo.size,
      backgroundImage: theme.hubSettings.logo.path,
      backgroundRepeat: "no-repeat",
    },
    closeIcon: {
      color: theme.hubSettings.logo.iconColor,
      overflow: "visible",
    },
    toolBarAdjust: theme.mixins.toolbar,
  })
);
interface LayoutProps {
  children: JSX.Element;
}
export const FilterContext = createContext(false);

const HubLayout = (props: LayoutProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [showFilters, setShowFilters] = useState<boolean>(false);

  const isMobileView = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.xs)
  );
  useEffect(() => {
    if (!isMobileView) {
      setOpen(true);
    }
  }, []);
  const noFilterPaths = [routes.eLearning];
  const pageHasFilters = !noFilterPaths.includes(window.location.pathname);
  const renderFilters =
    pageHasFilters && (!isMobileView || (isMobileView && showFilters));

  const [pageTitle, setPageTitle] = useState<string>("");
  return (
    <PreLoad>
      <>
        <div className={classes.root}>
          <AppBar
            position="sticky"
            className={clsx(`${classes.appBar}`, {
              [classes.appBarShift]: open,
            })}
          >
            <Toolbar className={classes.toolBar} variant="dense">
              <IconButton
                color="default"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, open && classes.hide)}
              >
                <MenuIcon
                  style={{ color: hgemColours.DarkGrey, overflow: "visible" }}
                />
              </IconButton>

              <UserToolbar
                drawerOpen={open}
                setShowFilters={setShowFilters}
                showFilters={showFilters}
                pageTitle={pageTitle}
              />
            </Toolbar>
          </AppBar>

          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={`${classes.drawerHeader} ${classes.clientLogo}`}>
              <IconButton onClick={handleDrawerClose}>
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            </div>
            <Divider />
            <NavigationDrawer
              isOpen={open}
              handleDrawerClose={handleDrawerClose}
              setShowFilters={setShowFilters}
              showFilters={showFilters}
              setPageTitle={setPageTitle}
            />
          </Drawer>

          <main
            className={clsx(classes.content, {
              [classes.contentShift]: open,
            })}
          >
            <FilterContext.Provider value={showFilters}>
              <PageContainer renderFilters={renderFilters} drawerOpen={open}>
                {props.children}
              </PageContainer>
            </FilterContext.Provider>
          </main>
        </div>
        <SessionManager />
      </>
    </PreLoad>
  );
};
export default HubLayout;
