import {
  ListItem,
  Typography,
  List,
  Icon,
  AccordionSummary,
  AccordionDetails,
  Accordion,
} from "@material-ui/core";
import SingleNavOption from "./singleNavOption";
import { NavigationOptionStyles } from "../styles/navigationOptionStyles";
import { INavigationOption } from "../../../state/types/UserProfile";
import { ExpanderOptionStyles } from "../styles/expanderOptionStyles";
import ReportPageNavigationList from "./reportPageNavigationList";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useEffect, useState } from "react";
import { i18n } from "../../../localizations";
import React from "react";
type props = {
  navOption: INavigationOption;
  setCurrentParentNav: (navOption: INavigationOption) => void;
  currentParentNav?: INavigationOption;
  currentChildNav?: INavigationOption;
  setCurrentChildNav: (navOption: INavigationOption) => void;
  isMobile: boolean;
  setDrawerOpen: (boolean: boolean) => void;
  selectedNavigationAccordion: INavigationOption | undefined;
  setSelectedNavigationAccordion: (navOption: INavigationOption) => void;
  setReportPageName: (pageName: string) => void;
};
const ExpandableNavOption = (props: props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {
    navOption,
    setCurrentParentNav,
    currentParentNav,
    currentChildNav,
    setCurrentChildNav,
    isMobile,
    setDrawerOpen,
    setSelectedNavigationAccordion,
    selectedNavigationAccordion,
    setReportPageName,
  } = props;
  const expanderClasses = ExpanderOptionStyles();
  const classes = NavigationOptionStyles();

  const isReportNavOption = (navOption: INavigationOption) =>
    navOption.path.includes("/reports/");

  const handleNavigationChange = (
    parentNavOption: INavigationOption,
    childNavOption: INavigationOption
  ) => {
    if (parentNavOption && parentNavOption.children) {
      setCurrentParentNav(parentNavOption);
      setCurrentChildNav(childNavOption);
    }
  };

  const isSelectedNavOption = navOption.name === currentParentNav?.name;
  const isSelectedAccordion =
    navOption.name === selectedNavigationAccordion?.name;
  const shouldCloseDrawerOnClick =
    navOption.name === "Inbox" ? setDrawerOpen : undefined;

  const handleExpanderClick = (navOption: INavigationOption) => {
    setIsOpen(!isOpen);
    setSelectedNavigationAccordion(navOption);
  };

  const expanderIconStyles = () => {
    if (isSelectedNavOption) {
      return expanderClasses.isSelected;
    }
  };
  useEffect(() => {
    if (isSelectedNavOption && !isSelectedAccordion && isOpen) {
      setIsOpen(false);
    }
    if (!isSelectedNavOption && !isSelectedAccordion && isOpen) {
      setIsOpen(false);
    }
    if (isSelectedNavOption && isSelectedAccordion && !isOpen) {
      setIsOpen(true);
    }
  }, [currentParentNav, selectedNavigationAccordion]);
  return (
    <>
      <ListItem
        className={expanderClasses.navOption}
        classes={{
          selected: classes.selected,
          root: expanderClasses.noPadding,
        }}
        selected={isSelectedNavOption}
      >
        <Accordion
          expanded={isOpen}
          className={expanderClasses.expanderButton}
          classes={{ root: expanderClasses.accordionRoot }}
          onClick={() => handleExpanderClick(navOption)}
        >
          <AccordionSummary
            className={expanderClasses.accordionSummary}
            classes={{
              root: expanderClasses.accordionSummary,
              expanded: expanderClasses.expandedSummaryHeight,
            }}
            expandIcon={<ExpandMoreIcon className={expanderIconStyles()} />}
          >
            <div className={classes.navOptionIconTitleContainer}>
              {navOption.icon && (
                <Icon
                  className={`${navOption.icon} ${expanderClasses.navOptionIcon}`}
                />
              )}
              <Typography variant="subtitle1">
                {navOption.navigationName[i18n.locale as string] ||
                  navOption.navigationName["en-GB"]}
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails
            classes={{ root: expanderClasses.accordionDetails }}
          >
            <List component="ul" style={{ width: "100%", marginLeft: "8%" }}>
              {navOption.children?.map(
                (childNavigationOption: INavigationOption, index) => {
                  return (
                    <React.Fragment
                      key={`${index}-${navOption.id || navOption.name}`}
                    >
                      <SingleNavOption
                        key={navOption.id || navOption.name}
                        navOption={childNavigationOption}
                        currentParentNav={currentParentNav}
                        currentChildNav={currentChildNav}
                        handleNavigationChange={(childNavOption) =>
                          handleNavigationChange(navOption, childNavOption)
                        }
                        isMobile={isMobile}
                        setDrawerOpen={shouldCloseDrawerOnClick}
                        isChild={true}
                        setReportPageName={setReportPageName}
                      />
                      {isReportNavOption(childNavigationOption) && (
                        <ReportPageNavigationList
                          navOption={navOption}
                          childNavigationOption={childNavigationOption}
                          currentChildNav={currentChildNav}
                          isMobile={isMobile}
                          setDrawerOpen={setDrawerOpen}
                          setReportPageName={setReportPageName}
                        />
                      )}
                    </React.Fragment>
                  );
                }
              )}
            </List>
          </AccordionDetails>
        </Accordion>
      </ListItem>
    </>
  );
};

export default ExpandableNavOption;
